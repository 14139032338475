<template>
  <v-card class="mx-auto" v-if="news.length > 0">
    <v-carousel :continuous="true" hide-delimiter-background hide-delimiters height="417px">
      <v-carousel-item
        v-for="(item, i) in news"
        :key="i"
        reverse-transition="fade-transition"
        transition="fade-transition"
        :to="{
          name: 'show-' + [namespace],
          params: { id: item.id }
        }"
      >
        <v-card-title
          >{{ $t("app.news") }} <v-spacer></v-spacer>
          <v-btn outlined small :to="{ name: 'list-news' }">{{
            $t("app.checkAll")
          }}</v-btn></v-card-title
        >
        <news-card :item="item" :limit-text="limitText" :icon="icon" :namespace="namespace" />
      </v-carousel-item>
    </v-carousel>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "NewsCarousel",
  computed: {
    ...mapGetters({
      loading: "options/loading",
      news: "news/latest"
    })
  },
  components: { NewsCard: () => import("@/components/core/cards/NewsCard") },
  created() {
    this.getData();
  },
  data() {
    return {
      limitText: "50",
      icon: "mdi-newspaper",
      namespace: "news"
    };
  },
  mounted() {},
  methods: {
    async getData() {
      await this.$store.dispatch("news/getLatest");
    }
  }
};
</script>
